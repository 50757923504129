import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { StyledLink } from '../components/Button/ButtonsStyles'

import BreadCrumbs from '../components/BreadCrumbs/BreadCrumbs';
import { Container, Row, Col } from 'react-bootstrap';
import { StyledH1, AlbumCard } from '../styles/albumStyles';

const Albums = ( { data } ) => {

  const renderAlbums = () => {
    return data.allContentfulAlbum.edges.map( album => {
      const { slug, albumTitle } = album.node;
      
      return (
        <Col xs={12} lg={4} key={slug}>
          <AlbumCard>
            <Img fluid={album.node.albumCover.fluid} alt={albumTitle} />
            <div className="buttonContainer">
              <StyledLink to={`/albums/${slug}`}>Listen Now</StyledLink>
            </div>
          </AlbumCard>
        </Col>
      )
    } )
  }

  return (
    <Layout>
      <SEO title="Music Archive" />
      <Container> 
        <BreadCrumbs archiveLink={'Albums'}/>
        <StyledH1>Albums</StyledH1>
        <Row className="d-flex-lg justify-content-lg-around">
          {renderAlbums()}
        </Row>
      </Container>
    </Layout>
  );
}

export default Albums;

export const pageQuery = graphql`
  query AlbumsQuery {
    allContentfulAlbum {
      edges {
        node {
          albumCover {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
          albumTitle
          slug
        }
      }
    }
  }
`
