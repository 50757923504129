import React from 'react';
import { Link } from "gatsby"

import { StyledBreadCrumbs } from './breadCrumbsStyles';

const BreadCrumbs = ( {archiveLink, singlePageTitle, color} ) => {
  const formatLink = (link) => {
    return link.toLowerCase();
  }

  return (
    <StyledBreadCrumbs color={color}>
      <li>Music</li>
      { archiveLink && <li><Link to={`/${formatLink(archiveLink)}`}>{archiveLink}</Link></li>}
      { singlePageTitle && <li>{singlePageTitle}</li>}
    </StyledBreadCrumbs>
  )
}

export default BreadCrumbs;
