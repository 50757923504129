import styled from 'styled-components';

export const StyledBreadCrumbs = styled.ul`
  color: ${props => props.color === "white" ? ({ theme }) => theme.colors.white : ({ theme }) => theme.colors.sapphireBlue};
  list-style-type: none;
  display: flex;
  padding-top: 24px;
  padding-bottom: 16px;

  @media (min-width: 1024px) {
    padding-top: 64px;
  }
  
  li {
    font-weight: 600;
    font-size: 14px;

    @media (min-width: 1024px) {
      font-size: 18px;
    }
  }

  li:not(:last-child) {
    &::after {
      content: '-';
      display: inline-block;
      font-size: 14px;
      margin: 0 6px;
    }
  }

  a {
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
  }
`;