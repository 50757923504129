import styled from 'styled-components';
import { Link } from "gatsby"

export const StyledButton = styled.button`
  display: inline-block;
  padding: 12px 24px;
  text-transform: uppercase;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.inkyBlack};
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: 16px;
  min-width: 160px;
  cursor: pointer;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

export const StyledLink = styled(Link)`
  display: inline-block;
  padding: 12px 24px;
  text-transform: uppercase;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.inkyBlack};
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: 16px;
  min-width: 160px;
  cursor: pointer;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;
