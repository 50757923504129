import styled from 'styled-components';

export const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.colors.sapphireBlue};
  margin-bottom: 24px;

  @media (min-width: 992px) {
    margin-bottom: 48px;
  }
`;

export const AlbumCard = styled.div`
  margin-bottom: 32px;

  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
`;